import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/BlogPost/BlogPost.js";
import Code from '@components/Code';
import fakeNewsGradientCode from './code/fake-news-gradient.example';
import ini from './code/ini.example';
import co1 from './code/co1.example';
import co2 from './code/co2.example';
import co3 from './code/co3.example';
import screenshot from './code/screenshot.example';
import pulumi from './code/pulumi.example';
import CenteredImage from '../../../components/CenteredImage';
import digitalocean1 from '../../../assets/images/digitalocean-mysql/digitalocean1.png';
import do0 from '../../../assets/images/digitalocean-mysql/do0.png';
import do1 from '../../../assets/images/digitalocean-mysql/do1.png';
import folder from '../../../assets/images/digitalocean-mysql/folder.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <blockquote>
      <p parentName="blockquote">{`This is a quick "Hands-on" tutorial, and I purposely avoid explaining every Term to keep it simple so you can just follow along quickly.`}</p>
    </blockquote>
    <h3>{`Prerequisites`}</h3>
    <ul>
      <li parentName="ul">{`Ansible version > 2`}</li>
      <li parentName="ul">{`Digitalocean Account`}</li>
    </ul>
    <h2>{`Step 1 - Create Droplet`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Click on the create button inside the Droplet page.`}</p>
        <CenteredImage width="100%" src={do0} alt="Create button" mdxType="CenteredImage" />
      </li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Select ubuntu (version 20.04 (LTS), Select whatever plan you wish, I choose the cheapest 6$ plan in the Premium Intel Cpu options and Choose your preferred location for the server`}</p>
        <CenteredImage width="100%" src={digitalocean1} alt="Create button" mdxType="CenteredImage" />
      </li>
      <li parentName="ul">
        <p parentName="li">{`under Authentication: make sure you select your SSH keys. Ansible will need to use the SSH Keys in order to connect with the server.`}</p>
        <CenteredImage width="100%" src={do1} alt="Create button" mdxType="CenteredImage" />
      </li>
    </ul>
    <ul>
      <li parentName="ul">{`Click on Create Droplet`}</li>
    </ul>
    <h2>{`Step 2 - Install MySQL on the server using Ansible`}</h2>
    <h3>{`What is Ansible ?`}</h3>
    <p>{`I like to think about Ansible as a the tool for "Day two operations" for servers. You can create your server manually or with other tools like terraform and with Ansible
you can configure that server using Infrastructure as code, automating all the manuall steps that you usually need to do when configuring or installing tools on the server.
In Ansible, we call our configuration files ‘playbooks’, and inside a ‘playbook’ we list sets of tasks ('Plays' in Ansible world) and they are written in Yaml.`}</p>
    <h3>{`what are Ansible Roles ?`}</h3>
    <p>{`Ansible Roles are a way to take related configuration (Playbooks), and package them together so they are working nicely together.`}</p>
    <ul>
      <li parentName="ul">{`For installing MySQL, we will use a great Ansible role called `}<a parentName="li" {...{
          "href": "https://github.com/geerlingguy/ansible-role-mysql"
        }}>{`"ansible-role-mysql"`}</a>{` that was created by Jeff Geerling  `}<a parentName="li" {...{
          "href": "https://github.com/geerlingguy"
        }}>{`geerlingguy`}</a>{`. Jeff Is the creator and maintainer of many great ansible roles
and his ansible roles are first on I always search for, he also have written a book about ansible that I can recommend `}<a parentName="li" {...{
          "href": "https://www.ansiblefordevops.com/"
        }}>{`Ansible for DevOps`}</a>{`. and no, the link is not affiliate, he just deserve the credit.`}</li>
      <li parentName="ul">{`Create a new empty folder to be used as the root folder called "my-awesome-ansible" and cd into it.`}</li>
      <li parentName="ul">{`inside the "my-awesome-ansible" directory, create a file called playbook.yml, this will be our ansible playbook that we will execute and inside that file we will include the ansible role.`}</li>
      <li parentName="ul">{`In the same directory as the playbbok.yml create a folder called "roles"`}</li>
      <li parentName="ul">{`In the same directory, create another directory called inventory and inside that directory create a file called all.ini, this is our inventory file where we "tell" ansible about our servers, in our case we will just have one server but Ansible can manage and configure multiple servers.`}</li>
      <li parentName="ul">{`Clone the repository `}<a parentName="li" {...{
          "href": "https://github.com/geerlingguy/ansible-role-mysql"
        }}>{`https://github.com/geerlingguy/ansible-role-mysql`}</a>{` into the roles folder.`}</li>
    </ul>
    <p>{`you should have the following folder structure:`}</p>
 <CenteredImage width="50%" src={folder} alt="Create button" mdxType="CenteredImage" />
    <ul>
      <li parentName="ul">{`open the all.ini file and insert the following:`}</li>
      <li parentName="ul">{`replace xxx.xxx.xxx.xxx with the ip address of your digitalocean server.`}</li>
    </ul>
    <Code lang="bash" mdxType="Code">{ini}</Code>
    <ul>
      <li parentName="ul">{`open the playbook.yml file and insert the following:`}</li>
    </ul>
    <Code lang="bash" mdxType="Code">{fakeNewsGradientCode}</Code>
    <p>{`In the above playbook, in the second line, we tell Ansible to run the playbook against the "All" group of servers and if you take a look at the all.ini inventory file, we can see that there is only one server in the group and it's the digitalocean one.
Bellow, we define the role "ansible-role-mysql" that should run (If you are not familiar at all with ansible, just keep in mind that if you run a "role" inside a playbook, then the tasks/main.yml file in that role is what actually executed. main.yml is the starting point for the Role)`}</p>
    <p>{`To run the playbook, we use the command "ansible-playbook", we give it the inventory file, the playbook to execute, and in our case also the user that Ansible should use inorder to connect with the server.`}</p>
    <ul>
      <li parentName="ul">{`let's first run it with a ""--check" option to just see what would happen:`}</li>
    </ul>
    <Code lang="bash" mdxType="Code">{co1}</Code>
    <ul>
      <li parentName="ul">{`Now re run it again without the --check option`}</li>
    </ul>
    <Code lang="bash" mdxType="Code">{co2}</Code>
    <h3>{`Check if everything is working`}</h3>
    <p>{`The root user is not allowed to connect to the MySQL from a remote connection, that was purposely done by the Ansible role as a security best practice (You can check the exact task in ansible-role-mysql/tasks/secure-installation.yml).
Inorder to connect to the database, first ssh into the digitalocean MySQL server that we just created and while your connected to the server, try to connect to the database. here's how it should look like:`}</p>
    <Code lang="bash" mdxType="Code">{co3}</Code>
    <p>{`The default root password is just "root", you should definitely change this to something more secure. Go to the ansible-role-mysql directory and open the file defaults/main.yml. change the values for`}</p>
    <pre><code parentName="pre" {...{}}>{`mysql_root_username: root

mysql_root_password: root
`}</code></pre>
    <br />
    <p>{`You now have a cheap (this also works great on the 5 dollar droplet) MySQL server running and configured in couple of minutes.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      